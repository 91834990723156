:root {
    --white: white;
    --gray: gray;
    --black: black;
    --silver: silver;
    --theemblue: #20ace2;
    --theemorange: #d88013;
    --brackground: #fafafa;
    --darkBackground: #eff0f5;
    --darkblue: #2e7aa2;
    --tomato: tomato;
    --ashyblack: #404346;
}


/* width */
::-webkit-scrollbar {
    width: 5px;
    height: auto;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e3e3e3;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--theemblue);
    border-radius: 10px;
    height: auto;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--darkblue);
}

/*Global shadow*/
.custom-shadow {
    box-shadow: 0 5px 30px rgba(50, 50, 75, .1);
}

.custom-shadow-without-top {
    box-shadow: 0 15px 20px rgba(50, 50, 75, .1);
}

.custom-box-shadow-inner {
    box-shadow: 0 5px 30px rgba(50, 50, 75, .1);
}

.custom-eliment-shaow {
    box-shadow: 0 0 20px #e7eaf0;
}

/*Global shadow*/
.dark-background {
    background-color: var(--brackground);
}

.dark-blue-background {
    background-color: var(--darkblue);
}

.white-background {
    background-color: var(--white);
}

.black-text {
    color: var(--black);
}

.white-text {
    color: var(--white);
}

.gray-text {
    color: var(--gray);
}

.border-radius-5 {
    border-radius: 5px;
}

.card-border-none {
    border: none !important;
}

/*@font-face {*/
/*    font-family: SutonnyMJBold;*/
/*    src: local('SutonnyMJBold'), url('../fonts/SutonnyMJBold.ttf') format('ttf');*/
/*}*/

/*@font-face {*/
/*    font-family: SutonnyMJRegular;*/
/*    src: local('SutonnyMJRegular'), url('../fonts/SutonnyMJRegular.ttf') format('ttf');*/
/*}*/

h1,
h2,
h3,
h4,
h5,
h6,
address {
    margin: 0 !important;
    /* font-family: SutonnyMJBold, serif !important; */
}

p {
    margin: 0 !important;
}

p,
ul,
li {
    list-style: none;
}

.TopBar ul li {
    display: inline-block;
    margin-left: 15px;
}

.top ul {
    display: inline;
}

.aladin-top-banner {
    padding: 0 !important;
}

.TopBar {
    background-color: #20ace2;
    font-size: 14px;
    padding: 2px 0;
}

.TopBar ul {
    text-align: right;
}

.TopBar a {
    color: #fff;
}

.TopBar a:hover {
    color: #fff;
}

.TopBar a span {
    color: #fff;
    /*padding: 10px;*/
    padding-right: 10px;
}

.TopBar ul li i {
    padding: 10px;
}

.from-width {
    width: 40% !important;
}

.cart-icon-item {
    display: flex;
    background: rgb(32 172 226);
    width: 51px;
    padding: 0 4px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 29px;
    cursor: pointer;
}

.fa-cart-arrow-down {
    font-size: 22px;
    margin-right: 5px;
}

.icon img {
    width: 15px;
    height: 20px;
}

.item h5 {
    font-size: 13px;
    padding: 4px 0 0 2px;
}

.cart-price {
    background: rgb(216 128 19);
    width: 51px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 15px;
    text-align: center;
    color: white;
    padding: 3px 3px 0.9px;
}

.cart-price h6 {
    font-size: 11px;
}

.cart-social-icon a {
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.cart-social-icon {
    text-align: center;
    font-size: 25px;
    background: #20ace2;
    height: 46px;
    width: 51px;
    border-radius: 5px;
    position: relative;
}

.cart-section {
    height: 45px;
}

.cart-icon-section-flex {
    display: flex;
    justify-content: space-between;
}

.cart-social-icon a {
    color: white;
}

.cart-social-icon a:hover {
    color: white;
}

.menu-left-icon {
    padding-right: 5px;

}

.navbar {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.select-btn {
    background-color: #d88013 !important;
    border: none !important;
    border-radius: 0 !important;
    padding-bottom: 10px !important;
    width: 94% !important;
    background-position: right 6.75rem center !important;
    padding-left: 85px !important;
}

.search-icon {
    right: 13px;
    position: absolute;
    top: 50%;
    font-size: 20px;
    color: gray;
    z-index: 100;
    cursor: pointer;
    transform: translate(0, -50%);
}

.search-area-control {
    border: none !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.search-input-group {
    background: white;
    border-radius: 4px;
    display: flex;
    flex-basis: 100%;
    margin: 0 auto;
}

.category-search-area {
    flex: 1;
}

.search-area {
    flex: 3;
}

.cart-design {
    display: flex;
    align-items: center;
}

.cart-design span {
    font-size: 30px;
    background: #d8d4d4;
    color: grey;
}

.cart-design .cart-design-inner h6 {
    font-size: 14px;
}

.head-line-bgcolor {
    background: rgb(88, 92, 94) !important;
    background: linear-gradient(85deg, rgb(240 240 240) 0%, rgba(255, 255, 255, 0.8911939775910365) 53%, rgb(240 240 240) 100%) !important;
    font-size: 14px !important;
}

.form-select:focus,
.catagores {
    padding-left: 20px !important;
}

.dropdown-color {
    color: #fff !important;

}

.logo {
    height: 70px;
}

.slick-dots {

    bottom: 10px !important;

}

.slider-img {
    position: relative;
}

.banner-inner img {
    height: 199px !important;
    width: 100%;
    border-radius: 5px;
    margin-top: 5px;
    padding-right: 1px;
}

.banner-inner {
    position: relative;
    margin-top: -2px;
}

.slider-inner {
    position: absolute !important;
    color: #fff !important;
    font-size: 20px !important;
    top: 0;
}

.bg-color {
    background-color: white;
    border-radius: 10px;
    padding: 22px 20px 20px 20px;
    margin: 0 13px;
}

.flash-deal-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: -9px;
    color: #333;
}

.img-and-title img {
    width: 25px;
    height: 25px;
    background: #f1f1f1;
    padding: 5px;
    border-radius: 20px;
    box-shadow: 0 0 5px #20ace2;
}

.img-and-title {
    display: flex;
    align-items: center;
}

.new-font-size {
    font-size: 20px;
    padding-left: 10px;
    color: #20ace2;
}

.flash-deal-inner a {
    font-size: 16px;
    color: #005891;
    padding: 4px 5px;
}

.flash-deal-inner a:hover {

    color: #20ace2;
}

.flash-deal-inner a span {
    font-size: 16px;

}

.more-to-love-padding {
    padding-left: 20px;
}

/*.flash-deal-inner h3 span {*/
/*    font-size: 25px;*/
/*    padding-right: 12px;*/

/*}*/

.row {
    margin-right: calc(var(--bs-gutter-x) * -0) !important;
    margin-left: calc(var(--bs-gutter-x) * -0) !important;
}

.category-navbar {
    width: 100%;
    justify-content: center !important;
}

.category-menu-nav {
    background-color: #d88013;
    height: 100%;
}

.category-menu-dropdown {
    width: 100% !important;
    cursor: pointer;
}

.category-navbar-nav {
    width: 100% !important;
}

.category-menu-dropdown-item {
    width: 100% !important;
}

.catagorymenu-color {
    background-color: #20ace2 !important;
    font-size: 14px !important;
    z-index: 1 !important;
}

.catagorymenu-font-size {
    font-size: 12px !important;
}

.menu-heading h4 {
    font-size: 18px;
    padding-top: 8px;
}

.menu-heading a {
    font-size: 14px;
}


/* catagorymenu css */

/* catagorymenu css end */

.headline-scroller span {
    margin-right: 150px;
}

.form-select:focus,
.form-control {
    box-shadow: none !important;
    border-color: silver !important;
}

.slick-prev {
    left: 1% !important;
    z-index: 99 !important;
}

.slick-next {
    right: 1% !important;
    z-index: 99 !important;
}

.slick-dots li button:before {
    font-size: 10px !important;
    color: white !important;
    opacity: .5 !important;
}

.slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: white !important;
}


.category_page_product_slider .slick-slide div div img {
    border-radius: 5px !important;
    margin-top: 1px;
    border: none !important;
}

.slider-banner-width .banner-width {
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding-top: 1px;
    padding-right: 1px;
}

.slider-banner-width .single-banner-width {
    width: 100%;
    min-height: 560px;
    border-radius: 5px;
    padding-top: 1px;
    padding-right: 1px;
}

.category-page-slider-image {
    border: 1px solid silver !important;
    border-radius: 10px !important;
    margin: 5px !important;
    padding: 5px !important;
}

.category_page_product_slider .slick-slide {
    padding: 0 2px;
}

.category_page_product_slider .slick-prev, .category_page_product_slider .slick-next {
    top: 43%;
}

.flash-deal-price small {
    font-size: 12px;
}

.flash-deal-discount {
    font-size: 12px;
    background: #20ace2;
    padding: 2px 5px;
    border-radius: 5px;
    color: white;
    float: right;
}

.flash-deal-price {
    font-size: 14px;
    color: var(--gray);
}

.flash-deal-sold {
    text-align: center;
    color: gray;
    margin-top: 10px;
    font-size: 14px;
}

.flash-deal-count-down {
    margin-left: 15px;
}

.flash-deal-count-hour,
.flash-deal-count-min,
.flash-deal-count-sec {
    background: tomato;
    padding: 0 4px !important;
    border-radius: 5px;
    color: white;
}

/* topbrand */
/* [type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    color: #5d616d !important;
} */

/* topbrand end */

/* img hover effect start */
.product-hover-inner {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    padding: 10px;
    border-radius: 20px;
    display: flex;
}

.product-minus {
    border: 2px solid white !important;
    box-shadow: none !important;
    color: white !important;
}

.img-relative {
    position: relative;
    width: 100%;
}

.card-hover {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: #303130de;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .3s ease;
    border-radius: 5px;
}

.img-relative:hover > .card-hover {
    bottom: 0;
    height: 100%;
}

.add-to-cart-and-view {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    top: 70%;
    display: flex;
    flex-direction: column;

}

.view-and-cart {
    border: none;
    border-radius: 20px;
    padding: 2px 9px;
    font-size: 12px;
    margin-top: 10px;
    background: #d88013;
    color: white;
}

.new-quantity-margin {
    margin: 0 5px !important;
    background: #eff0f5;
    box-shadow: none !important;
}

.hover-view-details {
    background: #eff0f5;
    color: gray;
}

.card img {
    width: 100%;
    /*height: 150px;*/
    object-fit: cover;
    object-position: center center;
    border-radius: 5px;
}

.card-body span {
    text-align: left;
}

/* img hover effect end*/

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

    /*.sidebar li {*/
    /*    position: relative;*/
    /*}*/
    .sidebar li .submenu {
        display: none;
        position: absolute;
        left: 100%;
        top: -7px;
        min-width: 240px;

    }

    .sidebar li:hover > .submenu {
        display: block;

    }
}

/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {

    .sidebar .submenu,
    .sidebar .dropdown-menu {
        position: static !important;
        margin-left: 0.7rem;
        margin-right: 0.7rem;
        margin-bottom: .5rem;
    }

}

/* ============ small devices .end// ============ */

.dropdown-menu {
    min-width: 100% !important;
    position: relative;
    top: 100% !important;
}

.category-dropdown-menu {
    top: 0 !important;
    transition: 1s ease;
    border: none !important;

}

.leftCategoryFont-color {
    color: #6d6868 !important;
}

.dropdown-menu {
    padding: 0 !important;
}

.right-category-dropdown {
    padding: 10px !important;
    font-size: 12px !important;
}

.category-nav-link {
    position: relative;
    border-bottom: 1px solid #f0f0f0;
    color: gray !important;
}

.category-nav-link::after {
    content: "";
    position: absolute;
    right: 15px;
    top: 15px;
    transition: all .5s ease;
    border: solid gray;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.category-nav-link:hover::after {
    right: 8px;
}

.custom-dropdown-toggle {
    margin-right: 15px !important;
}

.custom-dropdown-toggle::after {
    content: '';
    border-bottom: 3px solid red;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 1s ease;
}

.custom-dropdown-toggle:hover::after {
    content: '';
    width: 100%;
    transition: all 1s ease;
}

.coustom-dropdown {
    padding-top: 10px !important;
    font-size: 14px !important;
    /* background: #375c6b !important; */
}

.dropdown-toggle::after {
    display: none !important;
}

.slideInLeft {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;

}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-15px, 0, 0);
        transform: translate3d(-15px, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-15px, 0, 0);
        transform: translate3d(-15px, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInDown {
    -webkit-animation-duration: 1s;
    animation-duration: .25s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}


@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

/*category dropdown hover start*/
/*@media all and (min-width: 992px) {*/
/*    .navbar .nav-item .dropdown-menu{ display: none; }*/
/*    .navbar .nav-item:hover .nav-link{   }*/
/*    .navbar .nav-item:hover .dropdown-menu{ display: block; }*/
/*    .navbar .nav-item .dropdown-menu{ margin-top:0; }*/
/*}*/
@media all and (min-width: 992px) {
    .navbar .category-menu-dropdown .category-menu-dropdown-item {
        display: none;
    }

    .navbar .category-menu-dropdown:hover .category-menu-dropdown-item {
        display: block;
    }

    .navbar .category-menu-dropdown .category-menu-dropdown-item {
        margin-top: 0;
    }
}

/*category dropdown hover end*/
.home-category-menu-hover-disabled {
    text-align: center;
    top: 5%;
    margin: 8px auto 0;
    color: white;
}

.dropdown-icon {
    font-size: 10px;
}

.left-category-dropdown-indicator {
    font-size: 12px;
}

.left-category-dropdown-indicator::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    border: 10px solid transparent;
    border-bottom: 10px solid white;
    transform: translate(-50%, 0);
}

.left-category-image img {
    width: 96%;
    height: 69px;
    border: 1px solid black;
    margin-top: 10px;
}

/* category end */


.my-card {
    border-radius: .5rem !important;
    border: none !important;
    box-shadow: 0 0 10px #dad3d3;
    transition: all 0.2s ease 0s;

}

.my-card:hover {
    box-shadow: 1px 0 21px 0 rgba(0, 0, 0, 0.15) !important;
    -webkit-box-shadow: 1px 0 11px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 1px 0 11px 0 rgba(0, 0, 0, 0.15);
    transform: translate(0, -3px);
}

.card-img,
.card-img-bottom,
.card-img-top {
    border-radius: 5px !important;
}

.banner-img img {
    border-radius: 10px;
}

.card-body {
    text-align: center !important;
}

.mr20 {
    text-align: left !important;
}


.btn-check:focus + .btn,
.btn:focus {
    box-shadow: none !important;
}

.top-brand-button-color {
    color: #252222eb !important;
    background-color: #bfc5ceb5 !important;
    border: none !important;
    font-size: 12px !important;
}

.hotCatagorysHead {
    padding: 4px 12px;
}

.hotCatagorysBanner {
    position: relative;
}

.hotCatagorysSideBanner {
    height: 96%;
}


.hotCatagorysInner h5 {
    position: absolute;
    top: 16px;
    left: 15px;
    font-size: 16px;
    font-weight: 800;
    color: #5d616d;
}

.hotCatagorysBanner img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    object-position: center center;
}

.hotCatagorysSideBanner img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    object-position: center center;

}

.hotCatagorysHead {
    padding: 0 12px;
}

.hotcatagoris {
    padding: 14px;
}

.card .globalImg img {
    width: 100%;
    /*height: 44px;*/
    object-fit: cover;
    object-position: center center;
}

.globalTitle .card .card-body h5 {
    font-size: 14px;
    color: darkcyan;
    text-transform: capitalize;
}

.globalTitle .card .card-body p {
    font-size: 14px;
    color: gray;
    text-transform: capitalize;
}


/* Global-Shopping-New-Start */
.GlobalShoppingNewSingleBox {
    margin-bottom: 8px;

}

.GlobalShoppingNewInnarBox {
    /*height: 150px;*/
    background-color: #ffe4fd;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 0 10px #dad3d3;
}

.GlobalBoxColor {
    background-color: #dcf7ff;
}

.GlobalShoppingHeading h4 {
    font-size: 13px;
    color: #5d616d;
    font-weight: 600;
    margin-left: 13px;
}

.GlobalInnarRowPadding {
    padding: 1px 0 2px 0;
}

.col-md-4.GlobalInnarColPadding {
    padding: 6px;
}

.GlobalShoppingItemInnarImg {
    position: relative;
}

.GlobalShoppingItemInnarImg img {
    width: 100%;
    /*height: 112px;*/
    border-radius: 8px;
}

.GlobalShoppingItemInnarImg img:hover {
    opacity: .7;
    transition: .3s;
}

.GlobalShoppingPrice {
    position: absolute;
    bottom: -1px;
    background-color: rgba(255, 82, 51, .65);
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 20px;
}

.GlobalShoppingPrice h4 {
    text-align: center;
    color: #fff;
    font-size: 11px;
    padding: 2px;
    line-height: 16px;

}

.GlobalNewShoppingColBanner {
    padding: 0 2px;
}

.GlobalNewShoppingBanner {
    height: 97%;
}

.GlobalNewShoppingBanner img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding-left: 2px;
    object-fit: cover;

}

/* Global-Shopping-New-End */
/* Grocery-Zone-Start */
.GroceryZoneBannar img {
    width: 100%;
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
}

.GroceryZoneBannar {
    height: 100%;
}

.GroceryItemInner {
    margin-top: 15px;
}

.GroceryItemInner a h3 {
    font-size: 18px;
    font-weight: 600;
    color: #5d616d;
    margin-bottom: 12px;
}

/*.GroceryItemSlider img {*/
/*    width: 203px !important;*/
/*    height: 210px !important;*/
/*    object-fit: contain;*/
/*    margin-left: 1px;*/
/*    !* border:1px solid black; *!*/
/*}*/

.GroceryItemSliderImg {
    width: 98% !important;
    display: inline-block;
    /*height: 240px;*/
    background: #c7cbe0;
    border-radius: 5px;
    box-shadow: 0 0 5px gainsboro;
}

.GroceryItemSliderImgInner h5 {
    text-align: center;
    margin-top: 4px;
    font-size: 16px;
    padding: 10px 0;
}

.GroceryZonePaddingRight {
    padding-right: 0 !important;
}

/* Grocery-Zone-End */

/* More To Lore Start */
.ViwMoreButton .ViwMoreButtonColor {
    background-color: tomato;
    color: #fff !important;
    width: 100px;
    /*font-weight: 600;*/
    margin: 10px;
}

.more-to-love-width {
    margin: 0 11px;
}

/* More To Lore end */
/* Product-section-start */
.product-section {
    background: white;
}

.product-img-padding {
    padding: 21px 10px;
    border-radius: 5px;
}

.small-product-img {
    text-align: center;
}

.small-product-img img {
    width: 66px;
    height: 66px;
    border-radius: 11px !important;
    padding: 10px 5px;
    cursor: pointer;
}

.small-product-img a {
    padding-right: 10px;

}

.product-title {
    margin-bottom: 10px;
}

.star-review-order {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    margin-bottom: 15px;
}

.star-icon-color {
    color: var(--tomato);
}

.star-icon {
    margin-right: 5px;
    font-size: 12px;
}

.review-font-size {
    font-size: 12px;
}

.star-icon .rating {
    margin: 0 5px;
    color: var(--gray);
}

.star-icon .rating-arrow-icon {
    font-size: 10px;
    color: var(--gray);
}

.star-review-order .product-reviews {
    margin-right: 5px;
    color: var(--gray);
}

.star-review-order .product-reviews:hover {
    color: red;
}

.star-review-order .product-order-number {
    color: var(--gray);
    font-size: 12px;
}

.product-price {
    margin-bottom: 15px;
    background: #fbf7f7;
    padding: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    width: fit-content;
    align-items: center;
}

.product-price-card {
    background: #0dcaf0;
    width: fit-content;
    padding: 2px 5px;
    border-radius: 0 15px 0 15px;
    /*margin-right: 10px;*/
}

.product-margin {
    margin-left: 20px;
}

.product-price h5 {
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.product-discount {
    font-size: 11px;
    color: white;
}

.price-separator {
    width: 10px;
    height: 3px;
    background: #0dcaf0;
}

.product-color {
    margin-bottom: 15px;
}

.product-color-img img {
    width: 50px;
    height: 50px;
    margin-top: 5px;
    border-radius: 5px;
}

.product-color-img a {
    padding-right: 10px;
}

.product-size-inner {
    margin-top: 5px;
}

.product-size-inner ul {
    list-style: none;
}

.product-size-inner ul li {
    display: inline-block;
    margin-right: 8px;
    background-color: #fff;
    border: 1px solid #b4bacb;
    width: 40px;
    border-radius: 3px;
    text-align: center;
    color: gray;
}

.product-size-inner ul li:hover {
    border-color: #20ace2;
    color: gray;
}

.product-quantity {
    margin-top: 15px;
}

.product-delivery-time {
    margin-top: 15px;
}

.product-quantity .product-quantity-inner {
    margin-top: 5px;
}

.minus-btn,
.plus-btn {
    background: transparent;
    border: none;
    box-shadow: 0 1px 4px #b3b3b3;
    color: #bbb3b3;
    font-size: 12px;
    border-radius: 20px;
}

.quantity-input {
    width: 38px;
    margin: 0 10px;
    text-align: center;
    border: none;
    font-size: 12px;
    color: var(--gray);
    box-shadow: 0 1px 4px #b3b3b3;
    border-radius: 20px;

}

.quantity-input:focus {
    outline: none;
}


.product-quantity .product-quantity-inner .product-available {
    padding-left: 5px;
    font-size: 14px;
    color: gray;
}

.product-quantity .product-emi {
    margin-top: 15px;
    border: none;
    background: transparent;
    padding: 0;
    color: #20ace2 !important;
}

.product-heading-color {
    color: var(--gray);
    font-size: 12px;
}

.product-delivery-time h5 {
    margin-top: 10px;
    font-size: 16px;
    color: rgb(7, 6, 6);
}

.product-delivery-time h5 span {
    font-size: 14px;
    color: gray;
}

.buy-now-add-to-cart-btn-inner {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.buy-now-add-to-cart-btn-inner .buy-now-btn-color {
    background: #20ace2;
    color: #fff !important;
    margin-right: 12px;
    font-size: 14px;
}

.buy-now-add-to-cart-btn-inner .add-to-cart-btn-color {
    background: #d88013;
}

.offline-order {
    background: #011b3c;
    margin-top: 30px;
    color: white;
    font-size: 14px;
    font-family: Poppins, serif;
    border-radius: 5px;
    text-align: center;
    width: 45%;
}

.offline-order-title {
    background: #2e7aa2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

}

.custom-input-group {
    color: white !important;
    background-color: #ff6347 !important;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: Poppins, serif;
}

.custom-flash-sell-border {
    border-radius: 5px 111px 101px 5px !important;
}

.custom-sold {
    background: #e91e63 !important;
}

/*.custom-form-control{*/
/*    margin-left: -89px !important;*/
/*}*/

.flash-sell-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e91e63;
    margin-bottom: 10px;
    width: 98%;
}

.flash-sell-sold {

}

.flash-sell-font-size {
    font-size: 12px;
}


.buy-now-add-to-cart-btn-inner .product-fav-btn {
    width: 30px;
    height: 26px;
    border-radius: 30px;
    border: none;
    text-align: center;
    font-size: 18px;
    color: var(--gray)
}

.buy-now-add-to-cart-btn-inner .product-fav-btn:hover {
    background: tomato;

}

.buy-now-add-to-cart-btn-inner span {
    padding-left: 5px;
}

.buy-now-add-to-cart-btn-inner .fa-heart:hover {
    color: white;
}

.recommended-img img {
    width: 100%;
    height: 140px;
    object-fit: cover;
    object-position: center center;

}

.official-store-inner-img .official-store-img img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    object-position: center center;
}

.official-store-price {
    width: 100%;
    height: 48px;
}

.official-store-inner-btn .official-coustom-btn {
    background: #20ace2;
    color: #fff !important;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    width: 100px;
    height: 38px;
    margin-right: 10px;

}

.visit-store-btn {
    background: transparent;
    border: 1px solid tomato;
}

.sold-by {
    background: #fafafa;;

}

.sold-by-conct-section {
    padding-bottom: 10px;
}

.sold-by-inner h6 {
    color: #757575;
    font-size: 12px;
    margin-bottom: 4px;
}

.sold-by-inner h5 {
    font-size: 18px;
    color: #5a5454;
}

.sold-by-inner-contect {
    text-align: center;
    font-size: 20px;
}

.sold-by-inner-contect a {
    color: #20ace2;
}

.sold-by-section small {
    color: var(--silver);
}

.sold-by-section h6 {
    color: var(--gray);
    font-size: 14px;
    font-weight: bold;
}

.sold-by-section a {
    color: var(--silver);
}

.sold-by-section a:hover {
    color: var(--theemblue);
}

.sold-by-section p {
    font-size: 12px;
    line-height: 14px;
    color: var(--silver);
    height: 60%;
}

.sold-by-section .col-4 {
    border-right: 1px solid var(--silver);
    text-align: center;
}

.sold-by-section .col-4:last-child {
    border-right: none;
}

.sold-by-section h4 {
    color: var(--gray);
    font-size: 16px;
    padding-top: 5px;

}

.sold-by-section h5 {
    color: var(--gray);
}

.delivery-section-icon {
    color: var(--gray);
}

.delivery-information-section {
    background: #fafafa;
    margin-top: 10px;
    padding: 8px 0;
}

.delivery-information-inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.delivery-address-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d4cbcb;
    padding-bottom: 12px;
}

.delivery-address-inner span {
    padding-right: 5px;
    color: #333;
    font-size: 20px;
}

.delivery-address-inner h6 {
    font-size: 14px;
    padding: 0 5px;
    color: #333;

}

.delivery-address-inner a {
    color: #48ace2;
    /* font-weight: 600; */
    letter-spacing: .2px;
}

.home-delivery-address-inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
}

.home-delivery-address-inner h6 {
    font-size: 14px;
    padding: 0 5px;
    color: #333;
}

.home-delivery-address-inner h6 span {
    font-size: 12px;
    color: #333;
}

.push {
    margin-left: auto;
}

.home-delivery-address-inner span {
    padding-right: 5px;
    color: #333;
    font-size: 20px;
}

.free-shipping-section {
    background: #f3f3f3;
    font-size: 14px;
    padding: 10px;
    margin: 15px auto;
    box-shadow: 1px 1px 4px #e3e3e3;
}

.free-shipping-section p {
    line-height: 1.3;
    color: #333;

}

.cash-on-delivery {
    border-bottom: 1px solid #d4cbcb;
}

.cash-on-delivery span {
    padding-right: 5px;
    color: #333;
    font-size: 20px;
}

.cash-on-delivery h6 {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}

.review-section {
    /* padding-top: 15px; */
    border-top: 1px solid #d4cbcb;
}

.review-section-inner {
    border-right: 1px solid #d4cbcb;
}

.review-section h6 {
    font-size: 13px;
    color: #333;
    line-height: 1.2;
    padding: 13px 0;
}

.review-section p {
    text-align: center;
    font-weight: 600;
    color: #333;
    font-size: 16px;
    border-bottom: 1px solid #d4cbcb;
    padding-bottom: 13px;
}

.store-section {
    text-align: center;
    padding-top: 1rem
}

.store-section a {
    color: #48ace2;
    font-weight: 600;
    font-size: 14px;
}

.store-section a:hover {
    color: #2e7aa2;

}

.delivery-information-tabs {
    width: 100%;
    background: #48ace2;
    border-radius: 5px;
    text-align: center;
}

.delivery-information-tabs li {
    width: 50%;
}

.color-sm-btn {
    color: var(--gray) !important;
    width: 10px;
}

.react-tabs__tab--selected {
    background: #2e7aa2 !important;
    color: white !important;
    border-radius: 5px !important;
    border: none !important;
}

.react-tabs__tab {
    bottom: 0 !important;
    color: white !important;
}

.product-details-tab-list .react-tabs__tab {
    background: var(--theemblue);
    width: 25%;
    text-align: center;
    border-right: 1px solid silver;
}

.product-details-tab-list .react-tabs__tab--selected {
    background: var(--darkblue);
    border-radius: 0 !important;
    padding-top: 3px !important;
}

.product-details-tab-list .react-tabs__tab--selected::after {
    content: "";
    border-top: 4px solid tomato !important;
    position: absolute;
    width: 100%;
    top: -4px;
    left: 0;
    transition: all ease-in;
}

.review-display {
    display: flex;
    flex-basis: 100%;
    align-items: center;
}

.star-label,
.start-count {
    flex: 1;
}

.star-progress {
    flex: 5;
}

.progress {
    height: 5px !important;
}

.progress-bar {
    height: 5px !important;
    background-color: var(--theemorange) !important;
}

.accordion__button {
    background-color: #ffffff !important;
    padding: 10px !important;
}

.accordion {
    border: none !important;
}

.accordion__panel p {
    color: var(--gray);
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.breadcrumb {
    margin-top: 1rem;
}

.breadcrumb-item a {
    color: var(--theemblue);
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.recomended-img {
    background: var(--white);
    padding: 6px;
    color: var(--ashyblack);
    border-radius: 5px;
}

.coustomer-reviews-section img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.coustomer-reviews-section {
    background: #efeeee6b;
    padding: 0 10px;
    margin-top: 15px;
    box-shadow: 0 0 .5px black;
    border-radius: 5px;
}

.product-image img {
    border-radius: 5px;
}

.product-image {
    cursor: zoom-in;
}

.product-details {
    border-radius: 5px;
}

/*.delivery-section-z-index{*/
/*   z-index: -1;*/
/*}*/

/* Product-section-eng */


/* catagory-section-start */
.catagory-bannar-slider .catagory-img {
    width: 100%;
    margin-top: 10px;
    height: 350px;
    border-radius: 5px;
}

.brand {
    border-bottom: 1px solid #e2e2e2c9;
}

.catagory-star {
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    /*justify-content: space-between;*/
    font-size: 12px;
    /*padding-top: 5px;*/
}

.catagory-discount {
    font-size: 12px;
}

.catagory-price {
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 2px;
    color: var(--ashyblack);

}

.catagory-star h6 {
    font-size: 12px;
}

.catagory-body {
    text-align: center;
    /*box-shadow: 0 0 5px silver;*/
}


.catagory-body .card-title {
    font-size: 14px;

}

.grid-viwe {
    display: flex;
    justify-content: flex-end;
}

.grid-viwe p {
    font-size: 14px;
    margin-top: 2px !important;
}

.grid-viwe span {
    padding: 2px 3px;
}


.grip-horizontal:hover {
    cursor: pointer;
}


.select-item-end {
    justify-content: flex-end !important;
    align-items: center;

}

.related-categories {
    color: var(--gray);
}

.min-box,
.max-box {
    width: 10%;
    margin: 5px;
    color: var(--gray);
    text-align: center;
    border: none;
    box-shadow: 0 0 2px #a0a1a7;
    border-radius: 5px;
    font-size: 12px;
}

.min-box,
.max-box:focus-visible {
    outline: none;


}

.price-btn {
    border: none;
    background: #20ace2;
    color: var(--white);
    font-weight: 600;
    border-radius: 5px;
    font-size: 12px;

}

.brand {
    color: var(--gray);
    padding: 15px 0;
    font-size: 14px;
}

.brand h6 {
    color: var(--ashyblack);
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
}

.price-input-box {
    display: flex;
    align-items: center;
    /* background: var(--silver); */
}

.catagory-star-icon {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.star-icon-checkbox {
    margin-right: 5px;
    width: 15px;
    height: 15px;
}

/* catagory-section-end */


/* progress-section-start */
.coustom-progress-btn {
    padding: 0 8px !important;
}

.coustomer-review-section,
.star-label {
    color: var(--gray);
}

.product-value {
    color: var(--ashyblack);
}

.recomended-card-cody {
    padding: 5px !important;
}

.recomended-card-cody h5 {
    font-size: 14px;
}

.card-top-img-gutter {
    margin: 0 12px !important;
    --bs-gutter-x: 0.2rem !important;
    cursor: pointer;


}

.card-top-img-gutter .card:hover {
    box-shadow: 0 0 2px black;

}

.category-img .card:hover {
    box-shadow: 0 0 20px gainsboro;
    cursor: pointer;
}

.category-left-filter-area {
    padding: 15px;
    max-height: 100vh;
    overflow-y: scroll;
    /*margin-top: -8px;*/
    position: sticky;

}

.category-img:hover {
    transform: translate(0, -3px);
}

.category-img {
    transition: all 0.2s ease 0s;
}

.category-img-bg {
    background: white;
    padding: 10px;
    border-radius: 5px;
}

.related-cagory-pading {
    padding: 10px 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.category-item-img-border {
    border-radius: 5px;
    margin-top: 10px !important;
}

.Store-categories ul li a {
    color: var(--gray);
}

.Store-categories-title {
    color: var(--ashyblack);
    font-size: 14px;
    border-bottom: 1px solid #f4efef;
    padding-bottom: 5px;
}

.store-category-product {
    font-size: 12px;
    line-height: 1.5;
}

.store-category-product-padding {
    padding-top: 5px;
}

.Store-categories {
    border-radius: 5px;
}

.single-reviews {
    display: inline-block;
    background: #eaeaea;
    padding: 10px;
    border-radius: 5%;
}

.single-reviews .name-of-reviewrs {
    font-weight: bold;
}

.review-like,
.review-reply {
    cursor: pointer;
}

.filter-by-section {
    border-top: 1px solid silver;
    border-bottom: 1px solid silver;
    /* padding: 10px 0px; */
}

.filter-content {
    justify-content: flex-end !important;
}

.filter-content a {
    color: var(--gray);
}

.coustom-dropdown-filter {
    border: none;
    border-left: 1px solid silver;
    /* border-right: 1px solid silver; */
    padding: 10px 5px;
    background: none;

}

/* progress-section-end */

/* cart-section-start */

.product-cart-section {
    background: var(--white);
    margin-top: 20px;
    border-radius: 5px;
}

.product-cart-img .secure-icon {
    margin-left: 55px;
    width: 32px;
    font-size: 25px;
}

.product-cart-img .secure-checkout {
    color: #5d616d;
    font-size: 25px;
    margin-left: 15px;
}


.cart-timele {
    position: relative;

}

.timeline-box {
    border-bottom: 2px solid #e0e4f0;
    padding-bottom: 4px;
    width: 78%;
    position: relative;
}

.timeline-border {
    position: absolute;
    border: 2px solid tomato;
    width: 90px;
    bottom: -3px;

}

.timeline-color-box {
    position: absolute;
    top: -13px;
    left: -5px;

}

.timeline-color-box {
    width: 99%;
}

.timeline-color-box ul li {
    display: inline-block;
    width: 25%;
}

.timeline-color-box ul li p {
    font-size: 14px;
    margin-left: -15px;
    margin-top: 13px;
    color: #b4bacb;
}


.timeline-color-box ul li .list-item-color {
    background-color: #e0e4f0;
    color: #fff;
    width: 36px;
    height: 36px;
    font-size: 14px;
    border-radius: 50px;
    line-height: 37px;
    text-align: center;
}

.timeline {
    display: flex;
    justify-content: space-around;
    text-align: center;
    position: relative;
    z-index: 2;
}

.liner {
    position: absolute;
    width: 75%;
    height: 2px;
    background: silver;
    top: 25%;
    z-index: -2;
    left: 13%;
}

.liner-colored {
    position: absolute;
    background: #20ace2;
    height: 2px;
    top: 25%;
    left: 13%;
    width: 15%;
    z-index: -1;
}

.secure-checkout-icon {
    background: silver;
    width: 30px !important;
    height: 30px;
    border-radius: 50%;
    padding: 6px;
    color: white;


}

.list-item-icon img {

}

.list-line {
    height: 2px;
    background: silver;
    position: absolute;
}

.time-line-item p {
    color: gray;
    font-size: 14px;
}


.shopping-color {
    background-color: tomato !important;
}

.margin-left-pay {
    margin-left: 9px !important;
}

.cart-list {
    color: #20ace2 !important;
}

.shopping-cart-bg {
    background: var(--white);
    margin-top: 20px !important;
    padding: 0 15px 20px 12px;
    border-radius: 5px;
}

.shopphing-cart-section {

    padding-bottom: 10px;
}

.shopphing-cart-section, .select-all-shopping-cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 15px;*/
}

.maycart-store-name {
    /*box-shadow: 0 0 3px #a7a1a1;*/
    margin-top: 10px !important;
    border-radius: 5px;
}

.cart-single-product-row {
    padding: 15px 0;
    border-bottom: 1px solid #ece6e6;
}


.product-details-section {
    background: #f1f1f180;
    padding: 10px 0;
    margin-bottom: 15px;

}

.select-all-inner {
    padding-left: 5px;

}

.recycle span {
    padding-right: 10px;
    color: #20ace2;
    font-size: 10px;
    cursor: pointer;
}

.product-dropdown {
    font-size: 12px;
    border: none;
    padding: 0 8px;
    box-shadow: 0 1px 5px #d8c4c4;
    background: #f9fafb;
    color: gray;
    border-radius: 11px;
}

.product-dropdown-icon {
    font-size: 10px !important;
    color: gray;

}

.shopping-cart-product-color-img img {
    width: 18%;
    box-shadow: 0 2px 5px #e0d7d7;
    padding-top: 2px;
    margin: 1%;

}

.shopping-cart-product-size-inner a {
    background-color: #fff;
    border-radius: 3px;
    text-align: center;
    color: gray;
    box-shadow: 0 2px 5px #e0d7d7;
    font-size: 10px;
    padding: 5px 0;
    width: 18% !important;
    display: inline-block;
    margin: 1%;;
}

.shopping-cart-product-size {
    padding-top: 10px;
}

.shopping-cart-product-size-inner {
    padding-top: 2px;
}

.dropdown-shopping-cart {
    padding: 10px !important;
    min-width: 197px !important;
    max-width: 197px !important;
    background: white;
    box-shadow: 1px 1px 5px silver;
}

.product-tittle-inner span {
    font-size: 14px;
}

.product-tittle-inner a {
    font-size: 12px;
    color: var(--gray);
    padding-bottom: 5px;
}

.product-tittle-inner h6 {
    font-size: 12px;
    color: var(--gray);
    padding-bottom: 4px;
    padding-top: 3px;
}

.product-cart-price {
    padding-top: 15px;
}

.product-cart-price h3 {
    font-size: 15px;
    font-weight: 600;
    color: #555656;
}

.item-total h6 {
    padding-left: 5px;
    font-size: 12px;
    color: var(--gray);
    padding-top: 5px;
}

.buy-from-seller-btn {
    border: none;
    background: var(--theemblue);
    color: var(--bs-white);
    padding: 5px 10px;
    border-radius: 50px;
    margin-top: 20px;
    font-size: 13px;

}

.store-name-logo img {
    width: 50px !important;
}

.store-name-logo {
    margin-top: 10px;
}

.store-name-logo h6 {
    color: var(--gray);
    font-size: 14px;
}

.store-information {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.store-logo {

}

.store-name {
    margin-left: 10px;
    color: gray;
}

.store-logo img {
    width: 60px;
}

/*
.product-varient-btn{
    border: none;
    color: var(--gray);
    font-size: 14px;
} */
.product-img-item {
    display: flex;
    align-items: center;

}

.product-img-item img {
    height: 140px;
    width: 140px;
    padding: 15px;
}

.cart-store-name {
    padding: 0 10px;
}

.cart-store-inner {
    padding-top: 16px;
    padding-bottom: 10px;
    color: var(--gray);
    border-bottom: 1px solid #20ace2;
    width: fit-content;
}

.product-border {
    /*border-bottom: 1px solid #e9ebec;*/
}

.content-color h5 {
    color: var(--ashyblack);
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.subtotal {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: var(--gray);
}

.extra-border-total {
    border: 1px solid var(--silver);
    margin-bottom: 10px;
    margin-top: 10px;
}

.checkout-btn {
    color: #fff;
    background-color: #20ace2;
    font-size: 14px;
    font-weight: 600;
    border-radius: 30px;
    /*box-shadow: 0 2px 6px rgb(249 145 127);*/
    border: none;
    padding: 7px 15px;

}

.checkout-btn-section {
    margin-top: 20px;
    text-align: center;
}

.content-right-sidebar {
    padding: 15px;
    margin-top: 5px;
}

.payment-method {
    border-radius: 5px;
    padding: 14px 0;
    margin-top: 20px;
    background: white;

}

.payment-icon {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.payment-icon img {
    width: 26%;
    height: 40px;
    background: white;
    padding: 7px;
    box-shadow: 1px 0 5px silver;
    border-radius: 5px;
    margin: 5px;


}

/*.payment-method-title{*/
/*    position: relative;*/
/*}*/
.payment-method-title p {
    font-size: 14px;
    color: var(--gray);
    text-align: center;
    font-weight: 600;
    padding-bottom: 5px;
}

.pay-now-btn {
    width: 100px;
    height: 40px;
    color: var(--bs-white) !important;
    font-size: 16px !important;
}

.pay-now {
    text-align: center;
    padding: 20px;
}

/* cart-section-end */
/* Shipping-information start */
.new-shipping-address {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.add-new-shipping {
    background: #20ace2;
    padding: 5px 10px;
    border-radius: 20px;
    color: #fff;
    border: none;
    margin-bottom: 10px;
    font-size: 12px;
}

.shipping-information-inner {
    background: var(--white);
    padding: 30px 20px;
    margin-top: 20px;
    border-radius: 5px;
}

.shipping-address-flex {
    display: flex;
    border: 1px solid #f1ebeb;
    padding: 15px;
    border-radius: 20px;
    margin-top: 5px;
    background: #e8e3e36b;
    box-shadow: 0 5px 10px #dad3d3;
}

.new-select-modal-custom-width {
    max-width: 720px !important;
}

.padding-for-city-zip-code-select {
    margin-right: 10px;
}

.padding-for-city-zip-code-select:last-child {
    margin-right: 0;
}

.shipping-information {
    font-size: 14px;
    color: var(--ashyblack);
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;

}

.custom-margin-shipping {
    margin-top: 0;
    margin-bottom: 20px;
}

.address-name-and-phone {
    cursor: pointer;
    flex: 4 1;
}

.shipping-address-edit-btn {
    flex: 2 1;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.edit-and-delete-icon-for-shipping span {
    font-size: 10px;
    padding-left: 8px;
    color: gray;
    cursor: pointer;
}

.set-as-default-btn {
    border: none;
    font-size: 11px;
    background: #20ace2;
    border-radius: 20px;
    padding: 2px 5px;
    color: white;
}

.address-name-and-phone p {
    font-size: 12px;
    color: var(--gray);
    padding-bottom: 5px;
}

.modal-dialog-custom-width {
    max-width: 350px !important;
}

.modal-border-none {
    border: none !important;
    padding: 0 !important;
}

.shipping-from-padding .modal-header {
    justify-content: center;
    padding: 10px !important;
}

.shipping-modal-header-section .btn-close {
    margin: 5px 0 !important;
    font-size: 10px;
    border: 1px solid silver;
    border-radius: 20px;
}

.shipping-modal-header-section .btn-close:focus {
    box-shadow: none;
}

.shipping-modal-header-section {
    padding: 0 14px !important;
    margin-bottom: 20px !important;
}

.shipping-save-btn {
    justify-content: space-between !important;
    padding-bottom: 10px !important;
}

.sidebar-top-bg {
    background: var(--white);
    padding: 14px;
    border-radius: 5px;
    margin-top: 20px;
}

.default-as-check-box {
    font-size: 12px;
    color: gray;
}

.default-as-check-box span {
    padding: 5px;
}

.default-as-check-box .form-check-input:focus {
    box-shadow: none;
}

.shipping-modal-header-section h5 {
    font-size: 14px;
}

.shipping-from-padding {
    padding: 0 5px;
}

button.save-btn {
    border: none;
    padding: 5px 20px;
    border-radius: 20px;
    font-size: 12px;
    background: #20ace2;
    color: white;
}

.shipping-from-padding .form-control {
    font-size: 10px;
    background: #f7f7f7bf !important;
    border: none;
    border-radius: 20px;
}

.shipping-from-padding .category-search-area {
    font-size: 10px;
}

.shipping-from-padding .set-default {
    font-size: 14px;
    padding-left: 5px;
}

.flex-shipping-checkbox {
    display: flex;
    align-items: center;
}

.select-address-name-and-phone {
    border: 1px solid #e8e8e8;
    padding: 10px;
    border-radius: 10px;
    background: #f9f6f6;
    margin-bottom: 20px;
    cursor: pointer;
}

/*.select-address-name-and-phone:last-of-type{*/
/*    margin-bottom: 0;*/
/*}*/
.address-select-modal-body {
    padding: 0 5px 0 !important;
}

.address-select-modal-body .row:last-child {
    border: none !important;
}

.select-address-name-and-phone p {
    font-size: 12px;
}

/* Shipping-information end */


/*payment-section-start*/
.payment-method-tab-list {
    text-align: center;
}

.payment-method-tab-list .react-tabs__tab {
    width: 23% !important;
    text-align: center;
    color: black !important;
    border: none !important;
    margin: 5px;
    border-radius: 5px;
    height: 90px;
    background: white !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
}

.payment-method-tab-list .react-tabs__tab--selected {
    height: 110px;
    z-index: 99 !important;
    box-shadow: 0 -3px 3px rgba(0, 0, 0, .25);

}

.payment-method-tab-panel.react-tabs__tab-panel--selected {
    display: block;
    box-shadow: 0 0 5px rgba(0, 0, 0, .25) !important;
    z-index: 43 !important;
    position: relative;
    background: white;
    padding-top: 20px;
    padding-bottom: 10px;
    width: 96%;
    margin: -10px auto;
}

.payment-method-img img {
    max-width: 100%;
    height: 75px;
}

.payment-margin {
    margin-top: 20px !important;
    padding: 20px !important;
    border-radius: 5px !important;
}

.payment-title h4 {
    color: var(--ashyblack);
    font-size: 20px;
}

.bekash {
    width: 90%;
    margin: 0 auto;
}

.payment-completed-section {
    position: relative;
}

.payment-completed-section h1 {
    padding: 150px;
    text-align: center;
    color: gray;
}

/*payment-section-end*/


/*Follow-us Section Start*/
.merchent-section-padding {
    padding-top: 10px !important;
}

.merchant-logo-address-follow-section {
    border-radius: 5px !important;
}

.merchant-follow-live-chat {
    display: flex;
    flex-direction: column;
}

.merchant-follow-live-chat {

}

.follow-us-btn, .chat-btn {
    width: 120px;
    border-radius: 20px;
    padding: 3px 0;
    margin: 5px;
    border: none;
    color: var(--white);
    font-size: 14px;

}

.follow-us-btn {
    background: var(--theemblue);
}

.chat-btn {
    background: #d88013;
}

.merchant-logo {

}

.merchant-address {
    margin: 0 30px;
}

.merchant-address h5 {
    font-size: 16px;
    color: var(--gray);

}

.merchant-address address {
    font-size: 12px;
    color: var(--gray);
}

.merchant-address .star-icon-color {
    font-size: 10px;
    padding-right: 5px;

}

.merchant-address .merchant-review {
    font-size: 10px;
    color: var(--gray);
}

.merchant-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

}

.merchant-search-box-category {
    box-shadow: 1px 1px 5px #dad3d3;
}

.coustom-select {
    box-shadow: 1px 1px 5px #dad3d3;
    outline: none;
    border-radius: 5px;
    font-size: 12px;
    width: 105px;
}

.merchent-search-icon {
    right: -2px;
    position: absolute;
    top: 50%;
    font-size: 20px;
    color: gray;
    z-index: 100;
    cursor: pointer;
    transform: translate(0, -50%);
    height: 38px !important;
    background: #e9eef35e;
    border: none !important;
}

/*Follow-us Section end*/

/*category-list-item-section-start*/
.category-list-item-bg {
    background: white;
    margin-top: 20px;
}

.category-all-list-item-product {
    border-radius: 5px;
    /*padding: 10px;*/
    margin-bottom: 15px;
}

.category-list-item-product-image {
    cursor: pointer;
}

.category-list-item-product-image img {
    border-radius: 5px;

}

.category-list-item-product-name p {
    color: var(--gray);
    padding-top: 5px;
}

.category-list-item-product-name .category-list-item-product-title {
    font-size: 12px;
    color: var(--gray);
}

.category-list-item-product-name .star-icon {
    font-size: 12px;
    padding-top: 5px;
}

.list-item-review-line {
    padding: 0 5px;
}

.category-list-item-product-price p {
    font-size: 15px;
    color: var(--ashyblack);
    font-weight: 600;
}

.category-list-item-product-price-discount {
    font-size: 10px;
    color: var(--bs-gray);
    padding-top: 5px;
}

.category-list-item-product-price-discount .category-discount-shadow {
    padding: 2px;
    box-shadow: 0 0 2px;
    margin-right: 5px;
    border-radius: 5px;
}

.category-list-item-padding-top {
    padding-top: 10px;
}

/*category-list-item-section-end*/


/*All-merchant-shop-code-start*/
.all-shop-bg-section {
    background: white;
    padding: 30px;
    margin-top: 20px;
    border-radius: 5px;
}

.all-merchant-shop-inner {
    box-shadow: 1px 1px 5px #dad3d3;
    padding: 20px;
    border-radius: 5px;
}

.all-shop-cash-on-delivery-section {
    padding: 20px;
    box-shadow: 1px 1px 5px #dad3d3;
    text-align: center;
}

.all-shop-cash-on-delivery-section h5 {
    font-size: 28px;
    color: #20ace2;
    font-weight: 600;
    font-family: serif;
    /*text-align: center;*/
}

.all-shop-search-section {
    box-shadow: 1px 1px 5px #dad3d3;
    width: 75%;
    margin-left: 100px;

}

.all-shop-search-section-flex {
    display: flex;
    align-items: center;
}

.all-shop-search-section-control {
    text-align: center !important;
}

.all-merchant-shop-location {
    padding-left: 45px;
}

.all-merchant-shop-location span {
    background: #20ace2;
    padding: 5px 10px;
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.all-merchant-shop {
    margin-top: 20px;
}

.all-merchant-brand {
    margin-top: 20px;
    box-shadow: 1px 1px 5px #dad3d3;
    padding: 20px;
    border-radius: 5px;
}

.all-merchant-card {
    transition: all 0.2s ease 0s;
}

.all-merchant-card:hover {
    transform: translate(0, -3px);
    box-shadow: 0 0 20px gainsboro !important;
}

.all-merchant-brand .card {
    border: none !important;
    box-shadow: 1px 1px 5px #dad3d3;
}

.all-merchant-brand .card-title {
    font-size: 16px;
    color: var(--gray);
}

.all-merchant-view-more-button {
    text-align: center;
    margin-top: 20px !important;
}

.all-merchant-view-more-button button {
    background: #20ace2;
    border-radius: 20px;
    padding: 5px 20px;
    color: white;
    font-size: 16px;
    font-family: serif;
    font-weight: 600;
    letter-spacing: 1px;
    border: none;
}

.all-shop-search-section .search-area {
    text-align: center;
    width: 75% !important;
}

/*All-merchant-shop-code-end*/


/*merchant-section-code-start*/
.merchant-about-inner {
    justify-content: center;
}

.single-about-merchant {
    padding: 20px;
    margin-top: 20px;
    border-radius: 5px;
}

.single-about-merchant h5 {
    font-size: 25px;
    font-family: serif;
    font-weight: 600;
    color: var(--gray);
    letter-spacing: 1px;
}

.single-about-merchant p {
    font-size: 16px;
    font-family: serif;
    color: var(--gray);
    line-height: 1.5;
    letter-spacing: 0.5px;
}

/*merchant-section-code-end*/


/*user dashbord code start*/

.my-dashboard-inner {
    margin-top: 20px;
    border-radius: 20px;
    /* background: rgb(211 216 228); */
    background-image: url(../Images/clint-bg.jpg);
    background-color: #1eb7f5;
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-left: 3px;
    padding-top: 16px;
    padding-bottom: 12px;
}

.new-design-0 {
    box-shadow: 0 5px 12px -2px rgb(205 130 249) !important;
    background: linear-gradient(90deg, #cd35ec, #9e4ff6) !important;
}

.new-design-1 {
    box-shadow: 0 5px 12px -2px rgb(253 171 109) !important;
    background: linear-gradient(90deg, #f7b032, #fb5a1d) !important;

}

.new-design-2 {
    box-shadow: 0 5px 12px -2px rgb(134 117 251) !important;
    background: linear-gradient(90deg, #6814cc, #ffc107) !important;
}

.new-design-3 {
    box-shadow: 0 5px 12px -2px rgb(253 140 126) !important;
    background: linear-gradient(90deg, #f21e60, #fc5a13) !important;
}

.new-design-4 {
    box-shadow: 0 5px 12px -2px rgb(134 117 251) !important;
    background: linear-gradient(90deg, #ffc107, #20ace2) !important;
}

.new-design-5 {
    box-shadow: 0 5px 12px -2px rgb(205 130 249) !important;
    background: linear-gradient(90deg, #6c1987, #9e4ff6) !important;
}

.my-dashboard-side-bar {
    background: white;
    padding: 15px;
    border-radius: 5px;
    margin-right: 33px;
}

.my-dashboard-side-bar-inner h5 {
    text-align: center;
    background: #20ace2;
    color: white;
    font-size: 16px;
    font-family: serif;
    letter-spacing: 1px;
    border-radius: 5px;
    padding: 5px 0;
}

.my-dashboard-side-bar-inner a {
    display: block;
    color: var(--gray);
    padding: 5px;
    font-size: 13px;
    padding-left: 10px;
}

.my-dashboard-side-bar-inner a:last-of-type {
    border-bottom: none;
}

.my-dashboard-side-bar-inner a:hover, .my-dashboard-side-bar-inner-active {
    color: #424242;
    background: rgba(32, 172, 226, .2);
    border-radius: 5px;
}

.my-dashboard-side-bar-inner {
    margin-bottom: 15px;
    border-radius: 5px;
    /*padding: 5px;*/
}

.my-dashboard-side-bar-inner:last-of-type {
    margin-bottom: 0;
}

.my-address-email-icon {
    margin-top: 4px !important;
}

a.live-chat-menu {
    background: rgba(32, 172, 226, .90);
    color: white;
    border-radius: 5px;
    text-align: center;
    margin-top: 10px;
}

.my-profile-notification-live-chat {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.my-dashboard-main-content {
    border-radius: 5px;
}

/*.my-order-content-inner {*/
/*    margin-top: 10px;*/
/*}*/
.my-dashboard-header-bg {
    background: #272588;
    width: 100%;
    height: 160px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.my-profile-top-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.my-address {
    padding-left: 15px;
}

.my-profile-top-bar span {
    font-size: 30px;
    padding: 5px 15px;
    border-radius: 50%;
    background: gray;
    text-align: center;
    color: white;
}

.my-address h6 {
    font-size: 14px;
    color: white;
}

.my-address p {
    font-size: 14px;
    color: white;
}

.my-profile-top-bar-shadow {
    padding: 10px;
    border-radius: 5px;
    align-items: center;
    background: #ffffff42;
    margin: 0 4px 4px 4px !important;
}

.my-dashboard-overview {
    margin-left: -29px;
}

.my-profile-notification-live-chat span {
    color: white;
    padding-right: 10px;
}

.my-dashboard-overview-card {
    display: flex;
    /*align-items: center;*/
    padding: 15px 10px 10px 10px;
    border-radius: 5px;
    justify-content: center;
    /*margin: 5px;*/
    background: #ffffff;

}

.my-dashboard-overview-card-icon span {
    font-size: 50px;
    color: white;
    padding-right: 20px;
}

/*.my-dashboard-overview {*/
/*    border-radius: 5px;*/
/*    padding: 10px;*/

/*}*/

.profile-live-chat-bg {
    background: #20ace2 !important;
}

.my-dashboard-overview-card-content {
    padding-top: 15px;
}

.my-dashboard-overview-card-content p {
    color: white;
    text-align: center;
    font-size: 14px;
    /* font-weight: 600; */
    font-family: serif;
}

.my-recent-order h5, .my-active-order h5 {
    background: #20ace2;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px;
    font-family: serif;
    font-weight: 600;
    color: white;
}

.active-and-recent-order-bg {
    height: 350px;
    border-radius: 5px;
    background: #ffffff;
}

.recent-support-tickets-icon-float {
    float: right;
    color: white;
}

.label-open-and-close {
    background: gray;
    padding: 0 5px;
    color: white;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 12px;
}

.recent-support-ticket-list-inner {
    font-size: 14px;
    color: gray;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px; /* fallback */
    max-height: 32px; /* fallback */
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.support-ticket-heading {
    display: flex;
    align-items: center;
}

.recent-support-ticket-link {
    color: gray;
}

.recent-support-ticket-link:hover {
    color: gray;
}

.recent-support-ticket-content-list {
    padding: 10px;
}

.recent-support-ticket-content-list:hover {
    background: #fafafa;
}

.recent-support-ticket-list {
    overflow-y: scroll;
    height: 308px;
}

.recent-order-section-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    color: gray;
    font-size: 14px;
    box-shadow: 0 0 2px gainsboro;
}

.order-image-inner {
    width: 60px;
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 10px;
}

.recent-order-section {
    overflow-y: scroll;
    height: 308px;
}

.order-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px; /* fallback */
    max-height: 32px; /* fallback */
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    flex: 4;
}

.order-image {
    flex: 1;
}

.my-search-form-control {
    border: none !important;
    background: transparent !important;
}

.search-input-group-text {
    background: transparent !important;
    border: none !important;
}

.live-chat-search-input-group {
    border: 1px solid silver;
    border-radius: 20px;
    width: 85% !important;
}

.live-chat-title {
    height: fit-content;
}

.live-chat-title h4 {
    font-size: 17px;
    font-family: serif;
    color: var(--gray);
    margin-bottom: 10px !important;
    font-weight: 600;
}

.live-chat-padding {
    padding: 15px;
    margin-left: -12px !important;
    width: 74.7% !important;
    border-radius: 5px;
}

@media only screen and (min-width: 1400px) {
    .live-chat-padding {
        width: 74.7% !important;
    }
}

.live-chat-search span {
    color: var(--gray);
}

.search-border-bottom {
    border: 1px solid #f9f4f4;
    margin-top: 10px;
}

.live-chat-merchant-profile {
    margin-top: 15px;
    cursor: pointer;
}

.live-chat-merchant-profile img {
    border-radius: 10px;
}

.live-chat-merchant-name {
    font-size: 14px;
    color: var(--gray) !important;
    font-family: serif;
}

.live-chat-merchant-profile p {
    font-size: 12px;
    color: gray !important;
}

.live-chat-merchant-profile:hover {
    background: #f7f5f5;
    border-radius: 10px;
}

.live-chat-emoji-box {
    position: absolute;
    bottom: 50px;
    right: 68px;
    width: 20%;
    height: 15%;
    border: 1px solid #efe9e9;
    display: none;
}

.live-chat-text-writing {
    position: absolute;
    bottom: 10px;
    width: 63%;
    right: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.live-chat-btn {
    border: none;
    border-radius: 30px;
    padding: 5px 11px;
    margin-right: 10px;
}

.live-chat-sent-btn img {
    width: 40px;
    height: auto;
    cursor: pointer;
}

.chat-customer-details img {
    border-radius: 10px;
}

.chat-customer-details a {
    font-size: 14px;
    color: #333232;
    font-weight: 600;
    font-family: serif;
}

.chat-customer-details p {
    font-size: 12px;
    color: gray;
}

.chat-customer-details {
    border-bottom: 1px solid #efe6e6;
    padding: 10px;
}

.live-chat-search {
    border-bottom: 1px solid silver;
    padding-bottom: 15px;
}

.chat-page-chat-list {
    height: 80%;
    overflow-y: scroll;
}

.live-chat-message-sent {
    text-align: right;
    width: 80%;
    margin: 0 0 0 auto;
}

.live-chat-right-border {
    border-left: 1px solid #f5e8e8;
    height: fit-content;
}

.live-chat-message-received {
    padding: 10px;
    width: 80%;
}

.chat-receive p {
    background: #f7f3f3;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 12px;
}

/*}*/
.live-chat-message-sent p {
    background: #e6ebf7;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 20px;
    float: right;
    font-size: 12px;
}

.chat-receive {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.chat-receive-text:hover + .chat-received-time {
    display: block;
}

.chat-receive-text:hover + .chat-receive-action {
    display: none;
}

.chat-received-time {

    display: none;
}

.chat-received-time, .chat-sent-time {
    font-size: 10px;
    background: #f7f4f4;
    padding: 8px;
    border-radius: 10px;
    color: #464444;
}

.chat-sent-time {
    display: none;
}

.chat-sent-text:hover .chat-sent-time {
    display: block;
}

.chat-sent {
    display: flex;
    justify-content: flex-end;
}

.live-chat-message-received img, .live-chat-message-sent img {
    max-width: 30px;
    border-radius: 10px;
}

.chat-receive-action span, .chat-sent-action span {
    padding: 5px;
    color: #e2d8d8;
}

.chat-receive-action {
    display: none;
}

.receive-message-container, .sent-message-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.sent-message-container {
    justify-content: flex-end;
}

.live-chat-message-received:hover .chat-receive-action {
    display: block;
}

.live-chat-search-input-group span {
    font-size: 20px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    color: #c3b7b7;
    cursor: pointer;
}

/*user dashbord code end*/

/*others-section-code-start*/
.others-section {
    /*border-radius: 5px;*/
    padding-bottom: 20px;
}

.others-section h1 {
    font-size: 20px;
    color: #20ace2;
    padding: 20px;
}

.other-section-inner {
    padding: 10px;
    margin: 20px;
    border-radius: 5px;
}

.other-section-inner h4 {
    font-size: 16px;
    color: gray;
    letter-spacing: 1px;
    position: relative;
    width: fit-content;
}

.other-section-inner h4::after {
    content: "";
    width: 100%;
    height: 3px;
    background: #20ace2;
    position: absolute;
    bottom: -7px;
    left: 0;

}

.other-section-inner p {
    font-size: 13px;
    padding-top: 6px;
    text-align: justify;
}

.other-section-inner-title {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 5px;
}

.accordion-section .accordion__item:first-child {
    margin-top: 10px;
}

.accordion-section .accordion__item {
    border: none !important;
    box-shadow: 0 0 3px silver;
    margin-top: 20px;
}

.accordion-section .accordion {
    margin: 0 30px;
    padding: 20px;
    border-radius: 5px;
}

/*others-section-code-end*/

/*delivery-information-table-code-start*/
.delivery-information-table {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    border-collapse: collapse;
}

.delivery-information-table thead tr {
    background: #20ace2;
    color: white;

}

.delivery-information-table th, .delivery-information-table td {
    padding: 12px 15px;
    font-weight: 100;

}

table th:first-child {
    border-top-left-radius: 5px;
}

table th:last-child {
    border-top-right-radius: 5px;
}

.delivery-information-table tbody tr {
    /*border-bottom: 1px solid #dddddd;*/
}

.delivery-information-table tbody tr:nth-of-type(even) {
    background: #f3f3fe47;
}

.delivery-information-table tbody tr:hover {
    background: #f3f3fe;
}

.delivery-information-table tbody tr:last-of-type {
    border-bottom: 2px solid #20ace2;
}

.delivery-information-table-section h5 {
    text-align: center;
    font-size: 14px;
    color: gray;
    margin: 10px 0 !important;
}

/*delivery-information-table-code-end*/

/*all-policy-menu-section-code-start*/
.all-policy-menu-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

}

.all-policy-menu {
    padding: 7px 18.7px;
    font-size: 12px;
    color: gray;
}

.active {
    background: white;
}

/*.all-policy-menu:hover*/

/*all-policy-menu-section-code-end*/


/*.contact-section-code-start*/
.contact-us-section-padding-bottom {
    padding-bottom: 15px !important;
}

.contact-form .form-control {
    font-size: 12px;
    padding: 8px 15px;
    border: none;
    border-radius: 20px;
    background: linear-gradient(90deg, rgba(249, 253, 0.30), rgba(223, 243, 252, 0.35));
    box-shadow: 0 0 7px #e8e8e8 !important;
}

.contact-form .form-control:focus {
    outline: none;
}

.contact-form .form-control::placeholder {
    color: gray;

}

.get-in-touch {
    padding-bottom: 30px;
    margin: 0 auto;
}

.get-in-touch h4 {
    width: fit-content;
    font-size: 20px;
    color: #0096da;
    position: relative;
}

.get-in-touch h4:after {
    content: '';
    width: 100%;
    height: 3px;
    background: #0098db;
    position: absolute;
    bottom: -7px;
    left: 0;
}

.send-message-btn {
    background-image: linear-gradient(to left, #d6ebf5 0%, #67b0d0 51%, #0197da 100%);
}

.send-message-btn {
    padding: 10px 15px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 30px;
    border: none;
    font-size: 12px;
}

.send-message-btn:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
}

.contact-form-btn {
    text-align: center;
}

.contact-form form {
    padding: 2px 10px 2px 10px;

    border-radius: 5px;
}

.contact-form-and-img {
    border-radius: 5px;
    padding: 30px;
    margin: 40px auto;
    width: 70%;
}

.contact-information-section {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
}

.contact-information-inner:hover {
    box-shadow: 0 0 5px silver;
}

.contact-information-inner {
    background: #ffffff;
    text-align: center;
    padding: 20px 10px;
    border-radius: 5px;
    border: 2px dashed #dceaf1;
    margin-top: 15px;
    width: 20%;
    box-shadow: 0 5px 16px gainsboro;
}

.contact-information-inner:first-child {
    margin-top: 10px;
}

.contact-information-inner p {
    font-size: 14px;
    color: #0097db;
}

.contact-information-inner h6 {
    font-size: 14px;
    color: #0097da;
    letter-spacing: 1px;
}

.contact-information-inner span {
    font-size: 25px;
    color: #0097db;
}

.location-map {
    margin-top: 10px;
    padding: 15px;
    border-radius: 5px;
}

.location-map h2 {
    padding-bottom: 15px;
}

/*.contact-section-code-end*/


/*video-tutorial-section-code-start*/
.video-section {
    padding-bottom: 20px;
    width: 95%;
    margin: 0 auto;
}

.video-section-background {
    padding: 40px 20px;
    border-radius: 10px;
    margin-bottom: 24px;
}

.video-tutorial-section h1 {
    padding: 20px;
    text-align: center;
    color: #20ace2;
    font-size: 20px;
}

.video-title p {
    font-size: 12px;
    color: #20ace2;
    text-align: center;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
}

.video-icon div {
    font-size: 20px;
}

.video-play-icon span {
    color: white;
    position: absolute;
    transform: translate(8px, -1px);
    font-size: 14px;
}

div.video-play-icon {
    border-radius: 50%;
    display: block;
    -webkit-animation: ripple 1s linear infinite;
    animation: ripple 1s linear infinite;
    -webkit-transition: .6s;
}

.video-play-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    position: relative;
    z-index: 4;
    display: inline-block;
    border: 2px solid #ebebeb;
    cursor: pointer;
    background: #0073ec;
}

@-webkit-keyframes ripple {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 115, 236, 0.1), 0 0 0 10px rgba(0, 115, 236, 0.1), 0 0 0 20px rgba(0, 115, 236, 0.1);
        box-shadow: 0 0 0 0 rgba(0, 115, 236, 0.1), 0 0 0 10px rgba(0, 115, 236, 0.1), 0 0 0 20px rgba(0, 115, 236, 0.1);
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(0, 115, 236, 0.1), 0 0 0 20px rgba(0, 115, 236, 0.1), 0 0 0 30px rgba(0, 115, 236, 0);
        box-shadow: 0 0 0 10px rgba(0, 115, 236, 0.1), 0 0 0 20px rgba(0, 115, 236, 0.1), 0 0 0 30px rgba(0, 115, 236, 0);
    }
}

@keyframes ripple {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 115, 236, 0.1), 0 0 0 10px rgba(0, 115, 236, 0.1), 0 0 0 20px rgba(0, 115, 236, 0.1);
        box-shadow: 0 0 0 0 rgba(0, 115, 236, 0.1), 0 0 0 10px rgba(0, 115, 236, 0.1), 0 0 0 20px rgba(0, 115, 236, 0.1);
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(0, 115, 236, 0.1), 0 0 0 20px rgba(0, 115, 236, 0.1), 0 0 0 30px rgba(0, 115, 236, 0);
        box-shadow: 0 0 0 10px rgba(0, 115, 236, 0.1), 0 0 0 20px rgba(0, 115, 236, 0.1), 0 0 0 30px rgba(0, 115, 236, 0);
    }
}

button.modal_close-button {
    position: absolute;
    top: -27px;
    right: -24px;
    background: white;
    border: none;
    border-radius: 5px;
    color: red;
}

.video-btn {
    text-align: center;
}

.video-view-more {
    background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%)
}

.video-view-more {
    padding: 10px 20px;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 20px;
    border: none;
    font-size: 14px;
}

.video-view-more:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
}

/*video-tutorial-section-code-end*/

/*wishlist section code start*/
.wishlist-cart-btn {
    justify-content: flex-end;
    flex-direction: column;

}

.wishlist-cart-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 15px;
}

.wishlist-form-buy-now-btn-color {
    background: #20ace2 !important;
    font-size: 10px !important;
    margin-bottom: 10px;
    color: white !important;
    padding: 5px 10px !important;
}

.wishlist-form-add-to-cart-btn-color {
    background: #d88013 !important;
}

.wishlist-dropdown {
    margin-top: 15px;
}

/*wishlist section code end*/


/* Footer-Section Start */
.footer-section {
    background-color: #ffffff;
    padding: 20px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-top: 16px;
}

.item-total h3 {
    font-weight: 600;
    font-size: 20px;
    color: gray;
}

.item-total p {
    color: gray;
}

.addres-inner h4 {
    color: gray;
    text-align: center;
    font-size: 14px;
}

.addres-inner p {
    color: gray;
    text-align: left;
}

.address-icon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0 0 20px gainsboro;
    border-radius: 5px;
    padding: 10px;
    justify-content: center;
}

.address-icon .icon-inner {
    padding-right: 10px;
    font-size: 30px;
    color: #d88013;
}

.text-center {
    justify-content: center;
}

.text-right {
    justify-content: flex-end;
}

.fotter-innner {
    position: relative;
    /*border-bottom: 1px solid #efe9e9;*/
    /*padding-bottom: 20px;*/
}

.footer-about p {
    color: gray;
}

.footer-account-nav a {
    display: block;
    color: gray;
    /* text-align: center; */
    font-size: 12px;
    padding: 5px;
}

.footer-account-nav a:hover {
    display: block;
    color: #d88013;
}

.footer-inner-heading {
    text-align: center;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    background: #20ace2;
    padding: 4px;

}

.footer-about-padding {
    padding: 20px 0 0 0;
    margin-top: 20px !important;
}

.footer-social-icon span {
    padding-right: 10px;
    font-size: 20px;
}

.footer-social-icon .fa-twitter {
    color: #15aabf;
}

.footer-social-icon {
    padding: 22px 0;
    margin-left: 47px;
}

.footer-social-icon .fa-google-plus {
    color: #ee3414;
}

.follow-us {
    margin-left: 124px;
}

.follow-us p {
    color: gray;
}

.email-icon {
    width: 50px;
    height: 50px;
    background-color: tomato;
}

.search-feild {
    width: 100%;
    border: none;
    box-shadow: 0 0 5px gainsboro;
    border-radius: 20px;
    font-size: 12px;
    padding: 8px 20px;
}

.search-feild:focus {
    outline: none;
}

.search-button {
    width: 30px;
    height: 30px;
    background-color: tomato;
    border-radius: 20px;
}

.search-button img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
    width: 30px;
    filter: invert(100%);

}


.follow-email-button {
    position: relative;
    width: 100%;
    margin: 5px 0 0 0;

}

.search-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-7%, 7%);
}

.search-button span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
}

.footer-inner-buttom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.footer-inner-buttom p {
    color: gray;
    font-size: 12px;
}

.footer-inner-buttom ul {
    display: flex;
    flex-wrap: wrap;
}

.footer-inner-buttom ul li {
    list-style: none;
    padding-right: 6px;
}

.footer-inner-buttom ul li img {
    width: 55px;
    height: 30px;
}

.footer-inner-buttom-margin {
    margin-top: 20px !important;
}

.footer-account-nav img {
    width: 70%;
    margin: 5px;
}

.footer-account-nav {

    padding: 10px;
}

.footer-account-flex {
    display: flex;
    text-align: center;
}

.footer-account-nav-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-account-inner {
    box-shadow: 0 0 20px gainsboro;
    width: 25%;
    margin-left: 30px;
    border-radius: 5px;
    margin-bottom: 20px;
    flex: 1;

}

.footer-account-flex-2 {
    flex: 2;
}

.footer-about img {
    width: 60%;
    box-shadow: 0 0 20px gainsboro;
    border-radius: 5px;
    padding: 10px;
}

.fotter-innner::after {
    content: '';
    position: absolute;
    width: 98%;
    height: 2px;
    background: #f3f3f3;
    left: 0;
    right: 0;
    margin: 20px auto;
}

/*.social-icon-after{*/
/*position: relative;*/
/*}*/
/*.social-icon-after::after{*/
/*content: '';*/
/*position: absolute;*/
/*width: 98%;*/
/*height: 2px;*/
/*background: #ece4e4;*/
/*left: 0;*/
/*right: 0;*/
/*margin: 20px auto;*/
/*top: 35px;*/
/*}*/
.footer-app-title {
    font-size: 12px;
    color: gray;

}

.footer-bottom-hr {
    margin: 0 auto;
    width: 98% !important;
    border: 1px solid gainsboro;

}

/* Footer-Section end */
.bolg-section-inner {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-top: 10px;
}

.bolg-section-thumbnail {
    width: 32%;
}

.category-title-section {
    padding: 10px 0;
}

.name-and-date {
    display: flex;
    justify-content: space-between;
}

.blog-title {
    font-size: 14px;
    color: #20ace2;
    padding-top: 10px;
}

.blog-heading {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    color: black;
    padding: 10px 0;
    display: block;
}

.bolg-description {
    font-size: 12px;
    color: gray;
    padding-bottom: 10px;
    display: block;
}

.bolg-date-and-name {
    font-size: 12px;
    color: #20ace2;
}

.blog-thumbnail {
    border-radius: 5px;
    width: 100% !important;
    height: 200px !important;
}

/*blog-nav-menu*/
.blog-category {
    font-size: 20px;
    text-align: center;
    color: gray;
    padding: 10px 0;
}

.mainmenu a {
    display: block;
    background-color: #5591a9;
    text-decoration: none;
    padding: 5px 10px;
    color: white;
    font-size: 14px;
}

.mainmenu a:hover {
    background-color: #d9e0e2;
}

.mainmenu li:hover .submenu-blog {
    display: block;
    max-height: 200px;
}

.submenu-blog a {
    background-color: darkseagreen;
}

.submenu-blog a:hover {
    background-color: #d9e0e2;
}

.submenu-blog {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: all 0.5s ease-out;
}

.submenu-blog li:hover .subsubmenu {
    display: block;
    max-height: 200px;
}

.subsubmenu {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: all 0.5s ease-out;
}

.blog-details-inner {
    padding: 15px;
}

.blog-details-img img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

/*.blog-details-content{*/
/*    padding: 20px 0 0 0;*/
/*}*/
.blog-content {
    font-size: 14px;
    color: gray;
    padding: 10px 0 0 0;
}

.blog-details {
    box-shadow: 0 0 20px gainsboro;
    margin: 10px 0 0 0;
    padding: 20px;
    border-radius: 5px;
    background: white;
}

.bolg-details-title {
    font-size: 18px;
    color: #20ace2;
}

.blog-inner h4 {
    font-size: 16px;
    color: gray;
    letter-spacing: 1px;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
}

.blog-inner h4::after {
    content: "";
    width: 100%;
    height: 3px;
    background: #20ace2;
    position: absolute;
    bottom: -7px;
    left: 0;
}

.preview-and-next-btn {
    display: flex;
    justify-content: space-between;
}

.next-preview {
    border: none;
    background: #cbd1d4;
    color: white;
    font-size: 20px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-top: 10px;
}

.next-preview:hover {
    background: tomato;
}


.custom-close-button {
    color: white;
    font-size: 25px;
    background: gray;
    border-radius: 20px;
}

.modal-header-bg {
    border: none !important;
}

.custom-modal-button {
    text-align: right;
}

.custom-modal {
    width: 700px !important;
}

.custom-modal-body {
    padding: 13px 8px !important;
}

.modal-margin {
    margin: 10px 15px !important;
}

.btn-margin {
    margin-top: 10px !important;
}

.modal-small-img {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.modal-small-img img, .product-color-new img {
    width: 50px;
    border-radius: 5px;
    border: 1px solid #f1e7e7;
    cursor: pointer;
}

.product-color-new img {
    margin-right: 10px;
}

.custom-modal-content {
    border-radius: 20px !important;
}

/*.modal-header-bg{*/
/*    background: #03a9f4;*/
/*}*/

.custom-product-cart {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    box-shadow: 0 0 15px #eff0f5;
    z-index: 5000;
    border-radius: 5px;
}

/*Sign in section css star*/
.sign-in-logo {
    width: 33%;
    margin: 0 auto;
}

.sign-in-section-inner-new {
    padding: 10px;
    background: white;
    /*height: 65vh;*/
}

.sign-in-form-control {
    border: none !important;
    font-size: 12px !important;
    padding: 10px 15px !important;
    background: #e8f0fe !important;
    color: gray !important;
    margin: 25px 0 !important;
    border-radius: 20px !important;
    text-align: center;
}

.sign-in-and-forgot-pass {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chek-box-sign-in {
    display: flex;
    align-items: center;
}

.sign-in-and-forgot-pass {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: gray;
    font-family: Poppins, serif;
    margin-bottom: 25px;
    padding: 0 5px;
}

.remember {
    padding-left: 10px;
}

.sign-in-btn-new {
    border: none;
    padding: 2px 15px;
    background: #b4c4c7;
    color: white;
    border-radius: 30px;
    font-size: 16px;
    font-family: Poppins, serif;
    letter-spacing: .5px;
}

.sign-in-btn-new:hover {
    background: #00bcd4;
    color: white;
}

.forgot-pass {
    color: gray;
}

.sign-in-form-control:focus {
    background-color: #e8f0fe;
}

.form-check-input:checked {
    background-color: transparent;
    border-color: transparent;
}

.form-check-input:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}

.not-registered {
    text-align: center;
    font-size: 16px;
    font-family: Poppins, serif;
    color: gray;
    margin-top: 10px;
}

.customer-phone {
    font-size: 15px;
    font-family: Poppins, serif;
    letter-spacing: .5px;
}

.modal-header-padding {
    padding: 10px;
}

.modal-submit {
    justify-content: center;
}

.modal-submit-btn {
    background: #b4c4c7;
    color: white;
    border: none;
    padding: 5px 24px;
    border-radius: 20px;
}

.modal-submit-btn:hover {
    background: #0dcaf0 !important;
    color: white;
}

.new-from-control {
    width: 80%;
    margin: 10px auto;
}

.modal-close-btn {
    background: transparent;
    font-size: 20px;
    border: 2px solid gainsboro;
    border-radius: 20px;
    width: 20px !important;
    color: gray;
}

.chek-box-sign-in .react-toggle-track {
    width: 39px;
    height: 17px;
}

.chek-box-sign-in .react-toggle-thumb {
    width: 15px;
    height: 15px;
}

.chek-box-sign-in .react-toggle--focus .react-toggle-thumb {
    box-shadow: none !important;
}

.chek-box-sign-in .react-toggle--checked .react-toggle-thumb {
    left: 23px !important;
}

.sign-in-active-btn {
    background: #0dcaf0 !important;
    color: white !important;
}

.sign-in-active-btn:focus {
    box-shadow: none;
    outline: none;
}

.login-btn-new {
    background: #b4c4c7;
    width: 47%;
    border: none;
    font-size: 12px;
    margin: 5px;
    color: white;
    padding: 5px 0;
    border-radius: 5px;
}

.sign-in-form {
    width: 97%;
    margin: 0 auto;
}

.sign-in-modal-body {
    padding: 20px !important;
}

.signin-buttons {
    text-align: center;
    padding-top: 10px;
}

/*.signin-container {*/

/*    padding: 10px;*/


/*}*/
.new-sign-in-modal-title {
    font-size: 14px;
    color: gray;
    font-family: Poppins, serif;
}

.sign-in-logo {
    padding: 10px 0 12px 0;
}

.s-media-icon {
    font-size: 30px;
    color: gray;
    margin: 0 10px;
}

.create-account {
    color: #1FA2FF;
}

.sign-in-with-social-media-icon {
    text-align: center;
    padding-top: 15px;

}

.new-otp {
    width: 70% !important;
    margin: 0 auto !important;
}

.otp-resend-countdown {
    text-align: center;
    padding: 20px 0;

}

.resend-otp {
    text-align: center;
    font-size: 14px;
    font-family: Poppins, serif;
    color: gray;
    /*padding: 15px 0;*/
}

.or-sign-in-with {
    position: relative;
    color: gray;
}

.or-sign-in-with::before {
    content: '';
    position: absolute;
    width: 30%;
    height: 1px;
    background: #e8f0fe;
    top: 50%;
    left: 0;
    transform: translatey(-50%);
}

.or-sign-in-with::after {
    content: '';
    position: absolute;
    width: 30%;
    height: 1px;
    background: #e8f0fe;
    top: 50%;
    right: 0;
    transform: translatey(-50%);
}

.s-icon-section {
    padding-top: 15px;
}

.flash-deal-count-down-new {
    background: #e8f0fe;
    padding: 5px 15px;
    border-radius: 20px;
    color: gray;
}

.resend-otp-and-change-number {
    border: none;
    padding: 4px 10px;
    color: white;
    background: #0dcaf0;
    width: 28%;
    font-size: 10px;
}

.new-two-otp-btn-color {
    background: #e8f0fe !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: gray;
}

.border-left-otp {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.password-with-eye-icon {
    position: relative;
}

.eye-icon {
    position: absolute;
    z-index: 555;
    top: 50%;
    right: 10%;
    transform: translate(50%, -50%);
    color: gray;
    cursor: pointer;
}

/*Sign in section css end*/


/*repet css ontime start*/
/*Side navbar css start*/
.navbar-section-main {
    width: 95%;
    background: #1fa2ff3b;
    padding: 20px 0;
    border-radius: 5px;

}

.navbar-top-icon {
    text-align: center;
}

.navbar-search-section {
    text-align: center;
    position: relative;
}

.input-search-box {
    width: 90%;
    border: none;
    border-radius: 20px;
    background: #fbfbfb;
    font-size: 12px;
    padding: 0 10px;
    min-height: 23px;
}

.input-search-box:focus-visible {
    outline: none !important;
}

.rounded-logo {
    width: 50%;
    background: white;
    padding: 11px;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0 0 10px grey;
}

.top-search-icon-nav {
    position: absolute;
    top: 50%;
    right: 6%;
    transform: translate(-50%, -50%);
    color: gray;
}

.nav-menu-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 5px 15px;
    font-size: 16px;
    cursor: pointer;
}

.nav-main-menu:hover {
    background: #4f757ca6;
    border-radius: 20px 0 0 20px;
    /*position: relative;*/
}

.nav-main-menu:hover .nav-main-menu::after {
    content: '';
    width: 30px;
    height: 30px;
    background: red;
    position: absolute;
}

.dashboard-item {
    color: white;
    padding-left: 10px;
}

.dashboard-item:hover {
    color: white;
}

/*Side navbar css end*/
/*submenu section css start*/
.submenu-flex {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
}

.submenu {
    color: white;
    margin-bottom: 10px;
    font-size: 12px;
}

.nav-main-submenu {
    display: flex;
    justify-content: start;
    padding: 0 15px;
}

.submenu-title {
    color: white;
    padding-left: 15px;
}

.submenu-title:hover {
    color: white;
}

.nav-main-menu {
    margin-top: 10px;
}

/*submenu section css end*/
/*repet css ontime end*/

/*cart item css start*/
.cart-item-details {
    width: 300px;
    background: white;
    height: 100vh;
    position: fixed;
    right: 0;
    z-index: 1001;
    top: 10%;
    /*overflow-y: scroll;*/
}

.new-product-item {
    background: #181f2f;
    color: white;
}

.shopping-continue {
    cursor: pointer;
    padding: 8px;
    background: #0dcaf0;
    color: white;
}

.new-product-img {
    width: 70px;
    box-shadow: 0 0 2px gainsboro;
}

.new-product-item-show {
    display: flex;
    justify-content: space-around;
    padding: 10px 1px;
    align-items: center;
    box-shadow: 0 0 1px gainsboro;
    font-size: 12px;
    color: gray;
    margin-top: 6px;
}

.new-product-quantity-inner {
    display: flex;
    align-items: center;
}

.new-quantity-input {
    width: 27px;
    text-align: center;
    border: none;
    box-shadow: 0 0 1px #0dcaf0;
    font-size: 10px;
}

.left-btn, .right-btn {
    border: none;
    background: transparent;
    box-shadow: 0 0 1px #0dcaf0;
    color: gray;
    font-size: 10px;
}

.new-plus-minus-product {
    text-align: center;
    font-size: 12px;
}

.new-custom-price {
    padding-bottom: 10px;
}

.checkout-and-total {
    display: flex;
    align-items: center;
    background: #0dcaf0;
    color: white;
    justify-content: space-between;
    width: 300px;
    margin-left: auto;
}

.new-checkout-btn {
    border: none;
    background: #d88013;
    color: white;
    font-size: 14px;
    padding: 5px 41px;
}

.new-total-price {
    font-size: 14px;
    padding: 5px 28px;
}

.new-product-scrolling {
    height: 100vh;
    overflow-y: scroll;
}

.new-product-store-name {
    box-shadow: 0 0 5px gainsboro;
    margin: 10px 7px;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
}

/*cart item css end*/
/*Sticky section css start*/
.sticky-menu {
    background: #eff0f5;
    position: sticky;
    top: 0;
    z-index: 1020;
    box-shadow: 0 4px 5px silver;
}

/*#sticky_menu .container {*/
/*    padding: 0 2%;*/
/*}*/

/*.sticky-menu .container {*/
/*    padding: 2px 0 !important;*/
/*}*/

.sticky-menu .logo {
    height: 60px;
    padding: 10px 39px 10px 0;
}

.sticky-menu nav.sidebar.card.left-category-dropdown-indicator {
    width: 254px !important;
    left: -2px;
    margin-top: 15px;
}

.navbar-flex {
    justify-content: space-between !important;
    flex-direction: row !important;

}

.image-sticky {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sticky-menu .left-category-dropdown-indicator::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 6%;
    border: 10px solid transparent;
    border-bottom: 10px solid white;
    transform: translate(-50%, 0);
}

.sticky-dropdown-icon {
    display: flex;
    align-items: center;
    color: gray;
    font-size: 25px;
    padding-left: 2px;
}

.sticky-menu .dropdown-menu {
    background: transparent;
    border: none;
}

/*Sticky section css end*/
.new-card-content-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*Responsive code css start*/
@media only screen and (max-width: 1199px) {
    .cart-social-icon {
        width: 42px;
    }

    .cart-icon-section-flex {
        padding-left: 0 !important;
    }

    .flash-deal-discount {
        font-size: 10px;
    }

    .new-card-content {
        padding: 12px !important;
    }

    .sticky-menu .logo {
        padding: 10px 4px 10px 0;
    }

    .merchant-inner {
        flex-wrap: nowrap;
    }

    .category-search-area {
        font-size: 14px;
    }

    .all-policy-menu {
        padding: 7px 9.7px;
    }

    .all-shop-cash-on-delivery-section h5 {
        font-size: 22px;
    }

    .payment-method-tab-list .react-tabs__tab {
        width: 22.8% !important;
    }

    .product-details-tab-list .react-tabs__tab {
        font-size: 14px;
    }

}

/*Responsive code css start*/

/*All order section css start*/
.all-order-dropdown {
    background: white;
    padding: 10px;
    border-radius: 5px;
    margin-left: -25px;
    margin-right: 4px;
    margin-top: 12px;
    font-size: 14px;
    color: gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all-order-section-label {
    margin-right: 10px;
}

.all-order-select-section {
    border: none;
    box-shadow: 0 0 5px gainsboro;
    width: 100%;
    height: 30px;
    border-radius: 5px;
}

.transaction-list-search-box {
    position: relative;
}

.search-icon-absolute {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(50%, -50%);
    color: darkgray;
}

.transaction-list-search-box-main {
    border: none;
    box-shadow: 0 0 5px gainsboro;
    width: 162px;
    font-size: 12px;
    padding: 6px;
    border-radius: 5px;
}

.all-order-select-section, .transaction-list-search-box-main:focus-visible {
    outline: none;
}

.all-order-option-section {
    font-size: 14px;
    color: gray;
}

.main-all-order-section {
    display: flex;
    align-items: center;
}

.my-order-title {
    font-size: 16px;
    font-family: poppins, serif;
}

.order-table-section {
    background: white;
    padding: 10px;
    border-radius: 5px;
    margin-left: -25px;
    margin-right: 4px;
    margin-top: 12px;
    font-size: 14px;
    color: gray;
}

.order-table-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 5px gainsboro;
    padding: 10px;
    border-radius: 5px;
}


.all-order-product {
    width: 60px;
    border: 1px solid gainsboro;
    padding: 2px;
}


.main-product-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

.all-order-product-description {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px; /* fallback */
    max-height: 32px; /* fallback */
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    width: 30%;
}

.product-section-header {
    box-shadow: 0 0 5px gainsboro;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.heading-after {
    width: fit-content;
    position: relative;
}

.heading-after::after {
    content: '';
    width: -moz-fit-content;
    width: 100%;
    height: 2px;
    background: #1FA2FF;
    position: absolute;
    bottom: -4px;
    left: 0;
}

.all-transaction-table {
    background: white;
    padding: 10px;
    border-radius: 5px;
    margin-left: -25px;
    margin-right: 4px;
    margin-top: 12px;
    font-size: 14px;
    color: gray;
}

.all-transaction-list {
    background: #20ace2;
    color: white;
    font-family: initial;
    font-size: 16px;

}

.custom-table tbody tr:nth-of-type(even) {
    background: #f3f3fe47;
}

.custom-table tbody tr:last-of-type {
    border-bottom: 2px solid #20ace2;
}

.custom-table tbody tr {
    border-bottom: 1px solid #ffffff;
}

.custom-table {
    text-align: center;
}

.all-transaction-page-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.invoice-action-eye-icon {
    color: #f9f9f9;
    background: #20ace2;
    font-size: 20px;
    padding: 3px;
    border-radius: 5px;
}

/*All order section css end*/
/*user-profile-css-start*/
.user-profile {
    background: white;
    padding: 10px;
    border-radius: 5px;
    margin-left: -25px;
    margin-right: 4px;
    margin-top: 12px;
    font-size: 14px;
    color: gray;
}

.profile_wrapper {
    position: relative;
}

.profile_photo_area {
    width: 20%;
    margin: 0 auto;
}

#profile_photo {
    cursor: pointer;
}

.profile_photo_area img {
    border-radius: 50%;
    border: 5px solid silver;
}

#profile_photo_hover_area {
    margin-top: -35px;
    cursor: pointer;
}

.camera-wrappwer {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

#camera_icon {
    cursor: pointer;
}

#profile_photo_hover_area {
    margin-top: -35px;
    cursor: pointer;
}

#image_upload_button {
    margin-left: -16px;
    margin-top: -13px;
}

.profile_wrapper p {
    padding-top: 0px;
    margin-top: 15px;
}

.profile-name-and-email {
    padding-top: 20px;
}

.profile_form input {
    background-color: #eef4f7 !important;
    margin-bottom: 15px;
    border: none;
}
/*.common-table tr, .common-table td, .common-table th {*/
/*    !*border-width: 0!important;*!*/
/*    border: none;*/
/*}*/

.common-table tbody, .common-table td, .common-table tfoot, .common-table th, .common-table thead, .common-table tr{
    border-style: unset!important;
    box-shadow: 0 0 2px gainsboro;
}
/*user-profile-css-end*/
/*Shipping Address css start*/
.shipping-address-body {
    padding-top: 5px !important;
    padding-bottom: 0px !important;

}

.not_default_shipping_address_show {
    padding: 10px 10px 0px !important;
    margin-bottom: 0px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    color: black;
}

.shipping-address-update-button {
    font-size: 14px;
    padding: 1px 5px !important;
    height: 27px !important;
    margin-top: 7px !important;
}

.shipping-address-body button {
    float: right;
    margin-top: -2px;
    background: #dbdbdb;
    border: none;
    color: gray;
}
.address-button{
    background: #dbdbdb !important;
    border: none !important;
    color: gray !important;
}
.add-address-btn {
    background: #20ace2;
    border: none;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
}
.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

#open_create_shipping_form {
    cursor: pointer;
}

.form-opening-header h3 {
    margin-bottom: 0px;
}

.user-profile-shipping {
    background: white;
    border-radius: unset !important;
    padding: 10px;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    margin-left: -25px;
    margin-right: 4px;
    font-size: 14px;
    color: gray;
    margin-top: -5px;
}

.user-default-shipping-address {
    padding: 11px;
}

.default-address-action-area {
    display: flex;
    float: right;
}

.generate-pin {
    font-size: 28px;
    padding-bottom: 6px;
    color: #198754;
}

/*Shipping Address css end*/

/*Support Ticket Section css Start*/
.my-ticket-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my-ticket-search-box {
    position: relative;
}

.ticket-search-icon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(-50%, -50%);
    color: darkgray;
}

.ticket-input {
    border: none !important;
    box-shadow: 0 0 5px gainsboro !important;
    font-size: 14px !important;
}
.show-ticket{
    background: white;
    padding: 10px 0;
    font-size: 14px;
    color: gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ticket-table-heading {
    background-color: #eff1f7;
    color: #5d616d;
    font-size: 13px;
    font-family: initial;
}
.ticket-table-heading th{
    border: none!important;
}
.ticket-table-body{
    font-size: 12px;
}
.ticket-status-one, .ticket-status-tow, .ticket-status-three, .ticket-status-four {
    background-color: #e35252;
    color: #fff;
    border-radius: 15px;
    border-top-left-radius: 0;
    padding: 2px 11px;
    height: 26px;
}
.ticket-status-tow{
    background-color: #e98439 !important;
}
.ticket-status-three{
    background-color: #00bb64 !important;
}
.ticket-status-four{
    background-color: #e0da4a !important;
}
.ticket-table-body tr td a{
    color: gray;
}
.support-ticket-category-btn {
    border: none;
    margin: 10px;
    border-radius: 5px;
    padding: 5px 15px;
    color: #497796;
}
.support-ticket-category-btn:hover{
    background: #00bcd4;
    color: white;
}
.support-ticket-category{
    padding-top: 20px;
}
/*Support Ticket Section css end*/
/*Open Ticket Section css Start*/
.custom-card-block {
    border: 1px solid #e6e8ec;
    background: #fafafa;
    padding: 30px;
    border-radius: 6px;
}
.mb-40 {
    margin-bottom: 40px;
}
.account-eidt label {
    font-weight: 500;
    color: #757575;
    padding-bottom: 5px;
}
.open-ticket-section, .open-ticket-message-section{
    background: white;
    border-radius: unset !important;
    padding: 10px;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    margin-left: -25px;
    margin-right: 4px;
    font-size: 14px;
    color: gray;
    margin-top: -5px;
}
.open-ticket-message-section{
    margin-top: 15px;
    border-radius: 5px !important;
}
.open-text-area-inner {
    width: 100%;
    height: 140px;
    margin-top: 25px;
    border: none;
    box-shadow: 0 0 5px gainsboro;
    padding: 10px;
    border-radius: 5px;
}
.open-text-area-inner:focus-visible{
    outline: none !important;
    border: none !important;

}
.subject-on-ticket{
    border: none !important;
    box-shadow: 0 0 5px gainsboro !important;
    padding: 10px !important;
    border-radius: 5px !important;
}
.attach-file-main-section {
    display: flex;
    justify-content: end;
    align-items: center;
}
.reply-button-inner{
    border: none;
    outline: none;
    border-radius: 6px;
    font-size: 13px;
    background-color: #0dcaf0;
    color: #fff;
    padding: 4px 9px;
}
.attach-file-upload-icon{
    border: none;
    display: inline-block;
    padding: 4px 8px;
    cursor: pointer;
    background-color: #f1f1f1;
    border-radius: 4px;
    margin-right: 5px;
}
.submit-btn {
    border: none;
    padding: 6px 20px;
    border-radius: 5px;
    font-size: 16px;
    color: white;
    background: #0dcaf0;
}
.sub-heading {
    padding-top: 5px;
}
/*Open Ticket Section css end*/
.customer-order-details{
    background: white;
    padding: 10px;
    border-radius:5px;
    margin-left: -25px;
    margin-right: 4px;
    font-size: 14px;
    color: gray;
    margin-top: 5px;
}
.order-number-and-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px;
}
/*manage-order-css-start*/
.date-status-area {
    position: relative !important;
}
.card-body {
    padding-top: 15px !important;
}
.vl {
    border-left: 3px solid green !important;
    height: 80% !important;
    position: absolute !important;
    left: 26px !important;
    top: 15% !important;
    overflow: hidden !important;
}
.date-area {
    position: relative !important;
}
h6.date-h6 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
    margin-left: -42px !important;
}
.fa-arrow-alt-circle-up {
    background: white;
    padding: 3px 0;
}
.fast-one-spinner {
    position: absolute !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
    left: -4px !important;
    top: -1px !important;
}

p.status-time {
    margin-left: 25px !important;
    margin-top: 10px !important;
    margin-bottom: 6px !important;
    text-align: left;

}
.starting-arrow {
    position: absolute !important;
    left: 21.2px !important;
}
/*.fa-times-circle, .fa-stop-circle, .fa-pause-circle, .fa-arrow-alt-circle-up {*/
/*    background: white !important;*/
/*    padding: 3px 0 !important;*/
/*}*/
.margin-left-icon{
    margin-left: 20px !important;
}
.des-in-pickup {
    padding-left: 58px;
    text-align: left;
}

.order-details {
    text-align: center;
    font-size: 25px;
}