/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body{
  background: #eff0f5;
}

/* Remove default padding */
ul,
ol{
  padding: 0;
}
a{
  text-decoration: none;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}
.App{
  background-color: #eff0f5;
}
/*.container{*/
/*  width: 1200px !important;*/
/*}*/
@font-face {

  font-family: 'SolaimanLipi';
  src: local('SolaimanLipi'), url(Assets/fonts/SolaimanLipiNormal.woff2) format('woff2');
  src: local('SolaimanLipi'), url(Assets/fonts/SolaimanLipiNormal.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
body {

  font-family: SolaimanLipi, serif !important;
}



